import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';

import { CircularProgress } from '@mui/material';

import { Layout, Flex } from '@components/layout';
import { useAuth, useUser } from '@context';

const Escala = loadable(() => import('@screens/escala/Escala'));

function Index(props) {
  const auth = useAuth();
  const userProps = useUser();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user) {
      getInfo();
    }
  }, [auth]);

  const getInfo = async () => {
    const result = await userProps?.getUserInfo(auth?.user.uid);

    setUserInfo(result);
    setLoading(false);
  };

  if (loading) {
    return (
      <Layout key={0}>
        <Flex>
          <CircularProgress />
        </Flex>
      </Layout>
    );
  }

  if (!auth?.user?.uid) return <div />;

  if (userInfo?.selected !== 2) {
    navigate('/escala');
    return <div />;
  }

  const infoProps = {
    userId: auth.user.uid,
    userInfo,
  };

  return (
    <Layout {...props}>
      <Escala key="escala gestor" {...props} {...infoProps} />
    </Layout>
  );
}

export default Index;
